import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p> <br /> </p>
    <DescWrapper>
    <br /><br />
    <h1>What is Running the Dirty South all about?</h1>
    <br />
    <p>Running the Dirty South is a site to promote the races throughout the southeast areas of Tennessee, the Carolinas, Georgia, Alabama, and Florida. 
      There's a reason it's called the Beast Coast. With some of the toughest trail and mountain races in the country mixed with the gnarly trail conditions, hot and 
      humid weather conditions, it makes a recipe for some of the toughest races in the country. With some of the toughest terrain around you'll also
      find some of the nicest people around too.
      </p>
      <p>
        In addition to promoting races in the area, Running the Dirty South is about connecting runners. Are you heading to a new area you've never been 
        before and need some advice on where to run? Are you going to a new race and you need to find a pacer to get you through those long night miles? 
        Are you a Race Director that needs some extra volunteers? We hope that all of these will be reasons for you joining.
        In all things that I do, I want to inject more positivity into the culture of ultra trail and mountain running. I hope that you enjoy the site and come back often.
    </p>
    <p>-Ryan James</p>
    <br /><br />
    </DescWrapper>
  </Layout>
)

export default IndexPage


const DescWrapper = styled.div`
h1 {
  font-family: 'Cairo', sans-serif;
  font-weight: 900;
}
  display: grid;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 40px;
  // // margin-left: 150px;
  // // margin-right: 150px;  
  // text-align: left;
  // div:hover {
  //     box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.2), 0 16px 34px 0 rgba(0, 0, 0, 0.19);
  // }

  @media (max-width: 480px) {
      grid-template-columns: 1fr;
      margin-left: 20px;
      margin-right: 20px;
      
      .display-1 {
        font-size: 2.4rem;
        text-align: left;
      }
    }
`